import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const AboutMe = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="about" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            About <span className="text-red">Me</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">
          My name is Edward Arvinius, and I come from the vibrant city of Stockholm.          
          I consider myself to be a highly motivated individual, always eager to inspire 
          those around me with my enthusiasm for work. Learning new things and taking on 
          fresh challenges is something that I truly relish, and I'm known for giving my 
          all to any task that comes my way. Growing up, I was surrounded by entrepreneurship, 
          as both my parents owned and operated their own businesses. As a result, I developed 
          an entrepreneurial mindset from a young age and have a deep appreciation for the world of business.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <motion.div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"

              initial="hidden"
         whileInView="visible"
         viewport={{ once: true, amount: 0.5 }}
         transition={{ duration: 0.8 }}
         variants={{
           hidden: { opacity: 0, x: 50 },
           visible: { opacity: 1, x: -50 },
         }}

            >
              <img
                alt="About"
                className="z-10 image-size"
                src="assets/about-me5.png"
              />
            </motion.div>
          ) : (
            <img alt="About" className="z-10" src="assets/about-me5.png" />
          )}
        </div>
      </div>

      {/* About */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* Education */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">

            <img src="assets/education3.png" className="h-20 w-20" alt="education.png" />
             {/* <p className="font-playfair font-semibold text-5xl">01</p> */}
              <p className="font-playfair font-semibold text-3xl mt-3">
                Education
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 rounded-full bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <div className=" min-h-full overflow-hidden"> {/* add this div to set a max-height and hide overflowing text */}
          
          
          <p className="mt-5">
          <span className="text-sky-500">Karlstad University (Aug 2020 - present)</span>
          <br />
          Business Administration and 
          Economics(Civilekonomprogrammet) 
          </p>
          
          <p className="mt-5">
          <span className="text-sky-500">Stockholm University (Jan, 2020 - Jun, 2020)</span>
          <br />
          Business Administration
          </p>

          <p className="mt-5">
          <span className="text-sky-500">University of Idaho, USA (Aug, 2017 - Dec, 2019)</span>
          <br />
          Computer Science
          </p>

          <p className="mt-5">
          <span className="text-sky-500">Kungsholmens gymnasium/Stockholms musikgymnasium (Aug, 2013 - Jun, 2016)</span>
          <br />
          Social science - with a focus on choral singing
          </p>

          <p className="mt-5">

          <span className="text-sky-500">Currently</span>
          , I am pursuing a Bachelor's degree in Business Administration with a focus on Financial Economics
          at Karlstad University. Before this, I studied Computer Science for five semesters
          at the University of Idaho in the United States, where I sharpened my programming 
          abilities in multiple programming languages, including C, C++, Python, and Assembly Language. 
          While there, I also significantly improved my spoken English language proficiency.

          </p>
          </div>
        </motion.div>

        {/* WORK EXPERIENCE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">

            <img src="assets/work3.png" className="h-20 w-20" alt="work.png" />
             {/* <p className="font-playfair font-semibold text-5xl">02</p> */}
              <p className="font-playfair font-semibold text-3xl mt-3">
                Work Experience
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 rounded-full bg-red absolute right-0 top-0 z-[-1]" />
            
          </div>

        
          <p className="mt-5"> 
          <span className="text-sky-500">During the summer of 2022</span>
          , I worked as a Customer Experience Associate
           at IKEA Gallerian. In this role, I was responsible for customer service
            and cash register duties. I gained valuable experience in customer 
            service and teamwork, learning to always keep a smile on my face, 
            listen to the needs of customers, and collaborate with my colleagues
             to provide the best possible experience for customers. This job gave
              me a deep understanding of the importance of offering excellent 
              customer service, and I am confident that I can apply what I learned
               to a future career in the IT industry.
               <br /><br />
               <span className="text-sky-500">During the summer of 2021</span>, I worked as a Webmaster for Arbottna Herrgård, 
               a hotel located in Stockholm's archipelago. In this role, I was responsible for website design, content creation and 
               publication, event planning, and website maintenance. Through this experience, I gained expertise in web technology 
               using WordPress, as well as digital marketing and optimization using tools like Google Ads and MailChimp.
               <br />
                Additionally, I believe that building relationships and maintaining communication with the company's various functions, 
                customers, and stakeholders was just as important as my technical skills. This allowed me to better understand 
                and meet the needs of those I worked with, resulting in a more effective and collaborative work environment.

          </p>
        </motion.div>
        {/* SKILLS */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">

            <img src="assets/skills3.png" className="h-20 w-20" alt="skills.png" />
            {/*  <p className="font-playfair font-semibold text-5xl">03</p> */}
              <p className="font-playfair font-semibold text-3xl mt-3">
                Skills
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 rounded-full bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          <span className="text-sky-500">After studying Computer Science at the University of Idaho</span>
          , coding has been a passion of mine that I
           have spent a lot of time refining. Through YouTube tutorials and courses on Udemy,
            I have continued to expand my knowledge and skills in coding. I am self-taught in a variety
             of programming languages, including HTML, CSS, and JavaScript, and have gained proficiency
             in popular frameworks such as React.
             <br /><br />
             <span className="text-sky-500">For the past decade</span>
             , music production with the digital audio workstation FL Studio has been
              one of my main hobbies. I've spent countless hours improving my skills in this area and have
               produced numerous projects over the years. In addition to creating my own music, I have also
                had the opportunity to collaborate with other music producers and participate in various remix competitions in
                 online music communities. Through my dedication to music production, I have gained valuable
                  experience in areas such as sound design, mixing and mastering, and project management. 
                  It is a passion of mine that I am excited to continue pursuing and exploring in the future.

          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutMe;
