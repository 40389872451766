import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Landing = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="home"
      className=" md:flex md:justify-between md:items-center gap-16 md:h-full"
    >
      {/* IMAGE SECTION */}
      <div className="basis-2/5 z-10 mt-16 md:mt-32 flex justify-center md:order-2">
        {isAboveLarge ? (
         
         
         <motion.div
         initial="hidden"
         whileInView="visible"
         viewport={{ once: true, amount: 0.8 }}
         transition={{ duration: 0.8 }}
         variants={{
           hidden: { opacity: 0, x: 50 },
           visible: { opacity: 1, x: -50 },
         }}
       >
         <img
           alt="profile"
           className="z-10 w-full max-w-[400px] md:max-w-[600px]"
           src="assets/profile-pic7.png"
         /> 
       </motion.div>
        ) : (
         //Phone profile picture
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px]"
            src="assets/profile-pic7.png"
          />
        )}
      </div>

      {/* MAIN TEXT */}
      <div className="z-30 basis-2/5 mt-12 md:mt-32 pl-4 pt-2">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="xs:text-white text-6xl font-playfair z-10 text-center md:text-start xs:text-center">
            Edward {""}
            <span
              className="xs:relative xs:text-sky-600 xs:font-semibold z-20 
              before:absolute before:-left-[25px] before:-top-[70px] before:z-[-1]"
            >
              Arvinius
            </span>
          </p>

          <p className="mt-10 mb-7 text-sm text-center md:text-start">
          Hi there! Welcome to my personal website. Take a look around and let's connect.
          </p>
        </motion.div>

        {/* CALL TO ACTIONS */}
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-gradient-to-r from-cyan-900 via-sky-600 to-cyan-900 ... text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
            onClick={() => setSelectedPage("about")}
            href="#about"
          >
            About Me
          </AnchorLink>

          <a
  className="rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5"
  href="https://drive.google.com/file/d/1GxVLdpQQuLnaKMeMMCnaNGIBRroejwDo/view?usp=sharing"
  download="Resume.pdf"
>
  <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center px-10 font-playfair">
    Resume
  </div>
</a>




        </motion.div>
        
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
