import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-64 bg-red pt-10">
      <div className="w-10/12 mx-auto flex flex-col items-center">
        <SocialMediaIcons />
        <div className="flex items-center justify-center h-full">
          <p className="font-playfair font-semibold text-2xl text-yellow text-center">
            Edward Arvinius
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
