const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/edward-arvinius-248819117/"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="linkedin-link" src="../assets/linkedinnew1.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://github.com/EdwardArvinius"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="github-link" src="../assets/githubnew1.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="mailto: edward.arvinius@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="gmail-link" src="../assets/gmailnew1.png" />
      </a>
     {/* <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.facebook.com/eddie.arvinius/"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="facebook-link" src="../assets/facebooknew1.png" />
  </a> */}
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://open.spotify.com/artist/1lEe7BcwKpJCUGCGWXiqat"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="spotify-link" src="../assets/spotifynew1.png" />
      </a>



    </div>
  );
};

export default SocialMediaIcons;
